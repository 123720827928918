import type { DailyWheelId } from "@/types";

const useWheels = () => {
	const route = useRoute();
	const { open, isOpen } = useFunrizeModals();
	const { data: appInitData } = useAppInitData();
	const isGuest = useIsGuest();
	const { data: dailyWheelData, refresh: refreshDailyWheelData } = useGetDailyWheelData();
	const { data: luckyWheelData, refresh: refreshLuckyWheelData } = useGetLuckySpinData();
	const { entries } = useBalance({ options: { cached: true, immediate: false } });
	const { popupState } = usePopupsState();

	const isAvailableDailyWheel = computed(() => dailyWheelData.value?.wheelAvailable);
	const isAvailableLuckyWheel = computed(() => luckyWheelData.value?.wheelAvailable);
	const isShowDailyWheelForGuest = computed(() => isGuest.value && appInitData.value?.isShowDailyWheelForGuest);
	const isReasonTypeValid = computed(() =>
		["phone", "phoneAndProfile"].includes(dailyWheelData.value?.dailyWheel?.reasonType || "")
	);
	const isOnlyAvailableFree = computed(() => {
		const { isAvailableFree, isAvailableByProfileComplete, isAvailableByEmailConfirm } =
			dailyWheelData.value?.dailyWheel?.extraSpinData ?? {};

		return isAvailableFree && !isAvailableByProfileComplete && !isAvailableByEmailConfirm && isReasonTypeValid.value;
	});
	const isShowExtraSpinEmail = computed(
		() =>
			dailyWheelData.value?.dailyWheel?.extraSpinData?.isAvailableByEmailConfirm && !appInitData.value?.emailConfirmed
	);
	const isShowExtraSpinProfile = computed(
		() =>
			dailyWheelData.value?.dailyWheel?.extraSpinData?.isAvailableByProfileComplete &&
			!appInitData.value?.profileCompleted
	);
	const countWheels = computed(() => {
		if (isAvailableDailyWheel.value && isAvailableLuckyWheel.value) {
			return 2;
		}

		return 1;
	});
	const shouldShowDailyWheel = computed(() => {
		const loginFlow =
			popupState.value.isLoginFlow ||
			(route?.query?.social && route?.query?.action === "login" && !localStorage.getItem("showedDailyWheelPopup"));

		return loginFlow && !route?.hash?.includes("cash") && !isOpen("LazyOModalGame");
	});
	const prizes = computed(() => {
		const wheelId = dailyWheelData.value?.wheelId as DailyWheelId;
		const sectors = dailyWheelData.value?.wheelConfig?.wheels?.[wheelId]?.sectors;

		if (sectors) {
			return sectors.reduce((acc, item) => {
				if (Array.isArray(item)) {
					item.forEach((subItem) => acc.push(subItem || 0));
				} else {
					acc.push(item);
				}

				return acc;
			}, []);
		}

		return null;
	});

	const getMaxWinByWheel = computed(() => {
		if (prizes.value) {
			return prizes.value?.reduce<Record<"coins" | "entries", number>>(
				(acc, { coins = 0, entries = 0 }) => {
					if (coins > acc.coins) {
						acc.coins = coins;
					}
					if (entries > acc.entries) {
						acc.entries = entries;
					}

					return acc;
				},
				{ coins: 0, entries: 0 }
			);
		}

		return null;
	});

	const showDailyWheelPopup = () => {
		if (!shouldShowDailyWheel.value) {
			return;
		}
		if (
			(isAvailableLuckyWheel.value || dailyWheelData.value?.dailyWheel?.visible) &&
			(isAvailableDailyWheel.value ||
				isReasonTypeValid.value ||
				(dailyWheelData.value?.dailyWheel?.reasonType === "balance" && entries.value > 0))
		) {
			if (isAvailableLuckyWheel.value) {
				open("LazyOModalWheelLuckySpin", { location: "login" });
			} else {
				open("LazyOModalWheelDaily", { location: "login" });
			}

			dispatchGAEvent({
				event: "open_popup",
				form_name: "spin_win",
				step: "start",
				location: "login"
			});
		}
	};

	return {
		dailyWheelData,
		luckyWheelData,
		isAvailableDailyWheel,
		isAvailableLuckyWheel,
		isShowDailyWheelForGuest,
		isOnlyAvailableFree,
		isShowExtraSpinEmail,
		isShowExtraSpinProfile,
		isReasonTypeValid,
		countWheels,
		getMaxWinByWheel,
		showDailyWheelPopup,
		refreshDailyWheelData,
		refreshLuckyWheelData
	};
};

export default useWheels;
